import {Component, EventEmitter, Inject, OnInit, ViewChild} from "@angular/core";
import {UserModel} from "../../models/user.model";
import {AsyncPipe, DatePipe} from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { UserViewDialogComponent } from "../user-view-dialog/user-view-dialog.component";
import { UserService } from "../../services/user/user.service";
import { MatTabGroup, MatTabsModule } from "@angular/material/tabs";
import { SCondition } from "@nestjsx/crud-request";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import * as UserActions from "../../actions/user.actions";
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { firstValueFrom } from "rxjs";
import { ColumnModel, FullTableComponent } from "@overflo-srl/full-table";
import { FunctionListComponent } from "../../../role/components/function/function-list/function-list.component";
import { RoleListComponent } from "../../../role/components/role-list/role-list.component";
import { RoleModel } from "../../../role/model/role.model";
import { RoleService } from "../../../role/service/role.service";
import { HasPermissionPipe } from "../../../shared/pipes/has-permission/has-permission.pipe";
import { AppState } from "../../../shared/reducers";
import { SnackBarService } from "../../../shared/services/snackbar/snackbar.service";
import { ConfirmationDialogComponent } from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { FramePermissions } from "../../../shared/enums/frame.permissions";
import { MatButtonModule } from "@angular/material/button";

@Component({
  standalone: true,
  imports: [
    FullTableComponent,
    FunctionListComponent,
    RoleListComponent,
    HasPermissionPipe,
    AsyncPipe,
    MatTabsModule,
    MatButtonModule,
  ],
  providers: [
    HasPermissionPipe,
    AsyncPipe,
  ],
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit {
  readonly FramePermissions = FramePermissions;
  userListEnabled: boolean = true;

  @ViewChild('tabGroup', {static: false}) tab?: MatTabGroup;
  @ViewChild(RoleListComponent) roleListComponent?: RoleListComponent;
  @ViewChild(FunctionListComponent) functionListComponent?: FunctionListComponent;
  tabIndex: number = 0;

  actions = new EventEmitter<void | {
    type: string;
    element: UserModel;
  }>();

  join = [{ field: 'roles' }];

  search: SCondition = {};
  quickSearchColumns: string[] = ['username', 'roles.name'];
  columnList?: ColumnModel[];

  constructor(
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private userService: UserService,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private hasPermissionPipe: HasPermissionPipe,
    private roleService: RoleService,
    @Inject('dateTimeFormat') protected dateTimeFormat: string,
  ) {
  }

  async ngOnInit() {
    await this.prepareColumnList();

    this.route.queryParams.subscribe(params => {
      const roleIdParam = params['roleId'];
      if (roleIdParam) {
        this.search = { 'roles.id': {$eq: roleIdParam} }
      }
    });

    const roles = await this.getRoles();
    const isUserSuperadmin = await firstValueFrom(this.hasPermissionPipe.transform([FramePermissions.SUPERADMIN], true));

    this.actions.subscribe(async (action) => {
      if (action) {
        switch (action.type) {
          case "Impersonifica":
            this.impersonate(action.element);
            break;
          case "Modifica":
            console.log('action.element', action.element);
            this.openUserViewDialog(action.element);
            break;
          case "Elimina":
            const userRoles = action.element?.roles?.map((userRole) => roles.find((role) => role.id === userRole.id)) as RoleModel[];
            const isEditedUserSuperadmin = await firstValueFrom(this.hasPermissionPipe.transform([FramePermissions.SUPERADMIN], true, userRoles));
            if (!isUserSuperadmin && isEditedUserSuperadmin) {
              this.snackBarService.openErrorSnackBar(`Solo un utente Superadmin può eliminare un altro utente Superadmin`);
            } else {
              this.openDeleteConfirmationDialog(action.element);
            }
            break;
        }
      }
    });
  }

  async prepareColumnList() {
    const columns = [
      {
        def: "username",
        name: "Email",
        value: (element: UserModel) => element.username,
        sort: true,
        type: "string",
      },
      {
        def: "roles.name",
        name: "Ruoli",
        value: (element: UserModel) => element.roles?.map((role) => role.name).join(', '),
        sort: true,
        type: "string",
      },
      {
        def: "enabled",
        name: "Abilitato",
        value: (element: UserModel) => element.enabled,
        sort: true,
        type: "boolean",
      },
      {
        def: "lastLogin",
        name: "Ultimo login",
        value: (element: UserModel) => this.datePipe.transform(
          element.lastLogin,
          this.dateTimeFormat
        ),
        sort: true,
        type: "date",
      },
    ];

    const actions: any = {
      def: "actions",
      name: "AZIONI",
      value: [],
    };

    if (await firstValueFrom(this.hasPermissionPipe.transform([FramePermissions.SUPERADMIN], true))) {
      actions.value.push({
        icon: 'face',
        type: "Impersonifica",
      });
    }

    if (await firstValueFrom(this.hasPermissionPipe.transform([FramePermissions.userEdit]))) {
      actions.value.push({
        icon: 'edit',
        type: "Modifica",
      });
    }

    if (await firstValueFrom(this.hasPermissionPipe.transform([FramePermissions.userDelete]))) {
      actions.value.push({
        icon: 'delete',
        type: "Elimina",
      });
    }

    if (actions.value.length) {
      columns.push(actions);
    }

    this.columnList = columns;
  }

  canImpersonate(user: UserModel) {
    const permissions = new Map<string, boolean>();
    if (user?.roles) {
      for (const role of user?.roles) {
        if (role?.functions) {
          for (const roleFunctions of role?.functions)
            if (roleFunctions?.permissions) {
              for (const permission of roleFunctions?.permissions) {
                if (!permissions.has(permission.name)) {
                  permissions.set(permission.name, true);
                }
              }
            }
        }

      }
    }
    return (!permissions.has(FramePermissions.SUPERADMIN.toString()) &&
        !permissions.has(FramePermissions.ADMIN.toString()))
      || permissions.has(FramePermissions.SUPERADMIN.toString());
  }

  impersonate(user: UserModel) {
    if (!this.canImpersonate(user) || !user?.id) {
      this.snackBarService.openErrorSnackBar(`Impossibile impersonificare questo utente`);
      return;
    }
    this.store.dispatch(UserActions.impersonate({id: user.id}));
  }

  openDeleteConfirmationDialog(user: UserModel) {
    const userId = user.id as number;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: `Eliminazione utente`, 
        description: `Sei sicuro di voler eliminare l'utente con ID ${userId}?`
      }
    });
    dialogRef.afterClosed().subscribe(
      async (result) => {
        if(result) {
          await this.userService.deleteOne(userId);
          this.refreshUserList();
          this.snackBarService.openInfoSnackBar(`Utente eliminato con successo`);
        }
      }
    )
  }

  async openUserViewDialog(user?: UserModel) {
    let fetchedUser;
    if (user?.id) {
      const qb = new RequestQueryBuilder();
      qb.setJoin(this.join);
      fetchedUser = await this.userService.getOne(user.id, qb.query());
    }
    const dialogRef = this.dialog.open(UserViewDialogComponent, {
      data: { 
        user: fetchedUser || user,
      },
    });
    dialogRef.afterClosed().subscribe(async refresh => {
      if (refresh) {
        this.refreshUserList();
      }
    });
  }

  openRoleEditDialog() {
    if (!this.roleListComponent) {
      return;
    }
    this.roleListComponent.openRoleEditDialog();
  }

  openFunctionEditDialog() {
    if (!this.functionListComponent) {
      return;
    }
    this.functionListComponent.openFunctionEditDialog();
  }

  refreshUserList() {
    this.userListEnabled = false;
    setTimeout(() => {
      this.userListEnabled = true;
    });
  }

  async getRoles(): Promise<RoleModel[]> {
    const qb = new RequestQueryBuilder();
    qb.setJoin([{field: "functions"}, {field: "functions.permissions"}]);
    const roles = await this.roleService.getMany(qb.query());
    return roles || [];
  }
}
