import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MomentUtcDateAdapter } from './adapters/moment-utc-date.adapter';
import { RolesGuard } from './guards/roles/roles.guard';
import { ApplicationInterceptor } from './services/interceptor/Interceptor';

@NgModule({
  declarations: [
  ],
  imports: [
    RouterModule,
  ],
  exports: [
    RouterModule, 
  ],
  providers: [
    ApplicationInterceptor,
    RolesGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ApplicationInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: LOCALE_ID, useValue: "it-IT" },
    {
      provide: DateAdapter,
      useClass: MomentUtcDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class FrameSharedModule {}
