import {Component, EventEmitter, OnInit} from "@angular/core";
import {RoleModel} from "../../model/role.model";
import {MatDialog} from "@angular/material/dialog";
import {RoleEditDialogComponent} from "../role-edit-dialog/role-edit-dialog.component";
import {ConfirmationDialogComponent} from "../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import {RoleService} from "../../service/role.service";
import { firstValueFrom, from } from "rxjs";
import { ColumnModel, FullTableComponent } from "@overflo-srl/full-table";
import { HasPermissionPipe } from "../../../shared/pipes/has-permission/has-permission.pipe";
import { SnackBarService } from "../../../shared/services/snackbar/snackbar.service";
import { FramePermissions } from "../../../shared/enums/frame.permissions";

@Component({
  standalone: true,
  imports: [
    FullTableComponent,
  ],
  selector: `app-role-list`,
  templateUrl: "./role-list.component.html",
  styleUrls: ["./role-list.component.scss"],
})
export class RoleListComponent implements OnInit {

  rolesListEnabled: boolean = true;
  actions = new EventEmitter<void | {
    type: string;
    element: RoleModel;
  }>();

  columnList?: ColumnModel[];

  constructor(
    private dialog: MatDialog,
    private roleService: RoleService,
    private snackBarService: SnackBarService,
    private hasPermissionPipe: HasPermissionPipe,
  ) {}

  async ngOnInit() {
    await this.prepareColumnList();

    this.actions.subscribe(async (action) => {
      if (action) {
        switch (action.type) {
          case "Modifica":
            await this.openRoleEditDialog(action.element);
            break;
          case "Elimina":
            await this.delete(action.element);
            break;
        }
      }
    });
  }

  async prepareColumnList() {
    const columns = [
      {
        def: "name",
        name: "NOME",
        value: (element: RoleModel) => element.name,
        sort: true,
        type: "string",
      },
      {
        def: "description",
        name: "DESCRIZIONE",
        value: (element: RoleModel) => element.description,
        sort: true,
        type: "string",
      },
    ];

    const actions: any = {
      def: "actions",
      name: "AZIONI",
      value: [],
    };

    if (await firstValueFrom(this.hasPermissionPipe.transform([FramePermissions.roleEdit]))) {
      actions.value.push({
        icon: 'edit',
        type: "Modifica",
      });
    }

    if (await firstValueFrom(this.hasPermissionPipe.transform([FramePermissions.roleDelete]))) {
      actions.value.push({
        icon: 'delete',
        type: "Elimina",
      });
    }

    if (actions.value.length) {
      columns.push(actions);
    }

    this.columnList = columns;
  }

  async delete(role: RoleModel) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {data: {title: `Cancellazione ruolo`, description: `Sei sicuro di voler eliminare il ruolo ${role.name} ?`}});
    dialogRef.afterClosed().subscribe(
      (result) => {
        if(result) {
          from(this.roleService.deleteOne(role.id))
            .subscribe({
              next: value => {
                this.snackBarService.openInfoSnackBar(`Ruolo eliminato con successo`);
                return this.actions.emit();
              },
              error: err => {
                this.snackBarService.openErrorSnackBar(`${err.error.message}`);
                return this.actions.emit()
              },
            })
        }
      }
    )
  }

  openRoleEditDialog(role?: RoleModel) {
    const dialogRef = this.dialog.open(RoleEditDialogComponent, {data: {role: role}});
    dialogRef.afterClosed().subscribe(
      (result) => {
        if(result) {
          this.refreshRoleList();
          if (result !== true) {
            this.actions.emit();
          }
        }
      }
    )
  }

  create() {
    this.openRoleEditDialog();
  }

  refreshRoleList() {
    this.rolesListEnabled = false;
    setTimeout(() => {
      this.rolesListEnabled = true;
    });
  }

}
