<div class="component-wrapper">
    <div class="middle-title">
        <div>{{translatedEntityName}}</div>
        <button mat-raised-button color="primary" (click)="goToView()">Aggiungi {{translatedEntityName}}</button>
    </div>

    <mat-progress-spinner
        [diameter]="52"
        color="primary"
        mode="indeterminate"
        *ngIf="!entityListEnabled"
    ></mat-progress-spinner>

    <lib-frame-entity-table 
        [entityName]="entityName"
        (goToView)="goToView($event)"
        (openDeleteConfirmationDialog)="openDeleteConfirmationDialog($event)"
        *ngIf="entityListEnabled"
    >
        <ng-content></ng-content>
    </lib-frame-entity-table>
</div>