import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { LoginResponseDto } from '../../../shared/models/login.model';
import { logout, storeTokens } from '../../actions/auth.actions';
import { lastValueFrom, throwError, first, Observable } from 'rxjs';
import { AppState } from '../../../shared/reducers';
import { HttpCallerService } from '../../../shared/services/http-caller/http-caller.service';
import { UserModel } from '../../../shared/models/user.model';
import { getMe } from '../../selectors/auth.selector';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends HttpCallerService {
  private BASE_ROUTE = '/auth/';
  private LOGIN = this.BASE_ROUTE + 'login';
  private LOGOUT = this.BASE_ROUTE + 'logout';
  private REFRESH_TOKENS = this.BASE_ROUTE + 'refresh-tokens';
  private EDIT_PASSWORD = this.BASE_ROUTE + 'change-password';
  private REQUEST_PASSWORD_RESET = this.BASE_ROUTE + 'reset-password-request';
  private IS_PASSWORD_RESET_TOKEN_VALID = this.BASE_ROUTE + 'reset-password-token-still-valid';
  private RESET_PASSWORD = this.BASE_ROUTE + 'reset-password';

  constructor(
    protected override readonly http: HttpClient, 
    @Inject('BASE_PATH') protected backendBasePath: string,
    private store: Store<AppState>,
  ) {
    super(http, backendBasePath);
  }

  async getMe(): Promise<UserModel | undefined> {
    return await lastValueFrom(this.store.select(getMe).pipe(first()));
  }

  login(username: string, password: string): Observable<LoginResponseDto> {
    const url = `${this.backendBasePath}${this.LOGIN}`;
    return this.http.post<LoginResponseDto>(`${url}`, { username, password });
  }

  async editPassword(oldPassword: string, password: string, repeatPassword: string) {
    return await this.post(this.EDIT_PASSWORD, { 
      currentPassword: oldPassword, 
      newPassword: password, 
      newPasswordRepeat: repeatPassword
    });
  }

  async resetPassword(token: string, newPassword: string, newPasswordRepeat: string) {
    return await this.post(this.RESET_PASSWORD, { token, newPassword, newPasswordRepeat });
  }

  async requestPasswordReset(email: string) {
    return await this.post(this.REQUEST_PASSWORD_RESET, { email });
  }

  async isPasswordResetTokenValid(token: string): Promise<boolean> {
    return await this.post(this.IS_PASSWORD_RESET_TOKEN_VALID, { token });
  }

  impersonate(id: number) {
    return this.http.get<LoginResponseDto>(
      `${this.backendBasePath}/auth/impersonate/${id}`
    );
  }

  refreshTokens() {
    localStorage.setItem('isRefreshing', JSON.stringify(true));

    const url = `${this.backendBasePath}${this.REFRESH_TOKENS}`;
    const refreshToken = AuthService.getRefreshTokenFromLS();
    const user = AuthService.getUserFromLS();

    return this.http.post<LoginResponseDto>(url, { user, refreshToken }).pipe(
      tap((response: LoginResponseDto) => {
        localStorage.setItem('isRefreshing', JSON.stringify(false));
        return this.store.dispatch(storeTokens(response));
      }),
      catchError(e => {
        localStorage.setItem('isRefreshing', JSON.stringify(false));
        this.store.dispatch(logout());
        return throwError(e);
      })
    );
  }

  logout(): Observable<boolean> {
    const url = `${this.backendBasePath}${this.LOGOUT}`;
    return this.http.get<boolean>(url);
  }

  private static getRefreshTokenFromLS() {
    return JSON.parse(localStorage.getItem('refresh') || '{}');
  }

  private static getUserFromLS() {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }
}
