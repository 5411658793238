import { CommonModule, DatePipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class FrameEntityModule {
}