import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageVariablesEnum } from '../../enums/local-storage-variables.enum';

@Injectable({
  providedIn: 'root'
})
export class EnabledUserGuard implements CanActivate {
  
  constructor(
    private router: Router,
  ) {}

  canActivate(
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = localStorage.getItem(LocalStorageVariablesEnum.user) ? JSON.parse(localStorage.getItem(LocalStorageVariablesEnum.user) || '') : undefined
    const userAllowed = user?.enabled ? true : false;
    if (!userAllowed) {
      this.router.navigate(['disabled-user']);
    }
    return userAllowed;
  }
  
}
