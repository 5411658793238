import { DatePipe, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ColumnModel, FullTableComponent } from '@overflo-srl/full-table';
import { CommonFrameEntityFieldsEnum } from '../../../shared/enums/common-frame-entity-fields.enum';
import { FrameEntityType } from '../../../shared/enums/frame-entity-type.enum';
import { FullTableColumnType } from '../../../shared/enums/full-table-column-type.enum';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { frameEntityMapping } from '../../../shared/utils/frame-utils';
import { FrameEntityMetadataModel } from '../../models/frame-entity-metadata.model';
import { FullTableConfig } from '../../models/full-table-config.model';
import { FullTableEntityColumns } from '../../models/full-table-entity-columns.model';
import { FrameEntityHttpService } from '../../services/frame-entity-http/frame-entity-http.service';
import { SCondition, QueryJoin, QueryJoinArr } from "@nestjsx/crud-request";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-frame-entity-table',
  standalone: true,
  imports: [
    NgIf,
    FullTableComponent,
    DatePipe,
  ],
  templateUrl: './frame-entity-table.component.html',
  styleUrl: './frame-entity-table.component.scss'
})
export class FrameEntityTableComponent {

  @Input() entityName?: string;
  @Input() entityConfig?: FullTableEntityColumns;
  @Input() search!: SCondition;
  @Input() join!: QueryJoin | QueryJoinArr | (QueryJoin | QueryJoinArr)[];
  @Output() goToView = new EventEmitter<{entityName?: string, entityId?: number}>();
  @Output() openDeleteConfirmationDialog = new EventEmitter<any>();

  @Input() columnList?: ColumnModel[];
  actions = new EventEmitter<void | {
    type: string;
    element: any;
  }>();
  @Input() actionsEnabled: boolean = true;

  constructor(
    @Inject('fullTableConfig') protected fullTableConfig: FullTableConfig,
    @Inject('dateFormat') protected dateFormat: string,
    private frameEntityHttpService: FrameEntityHttpService<any>,
    private datePipe: DatePipe,
    private translationService: TranslationService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    if (!this.entityName) {
      return;
    }

    this.route.queryParams.subscribe(params => {
      const filtersParam = params[`${this.entityName}Filters`];
      if (filtersParam) {
        const filters = JSON.parse(filtersParam);
        const search: any = {};
        Object.keys(filters).forEach((key) => {
          search[key] = { $eq: filters[key] };
        });
        this.search = search;
      }
    });

    if (!this.entityConfig) {
      this.entityConfig = this.fullTableConfig?.entityColumns?.[this.entityName];
    }

    const joinConfig = this.fullTableConfig?.entityColumns?.[this.entityName]?.join;
    if (!this.join && joinConfig) {
      this.join = joinConfig;
    }

    this.prepareFullTable();
  }

  async prepareFullTable() {
    if (!this.entityName) {
      return;
    }
    const metadata = await this.frameEntityHttpService.getMetadata(this.entityName);
    const generatedColumns = this.getGeneratedColumns(metadata);
    let configuredColumns = this.applyColumnsConfig(generatedColumns);

    const allowedColumns = this.entityConfig?.allowedColumns;
    if (allowedColumns?.length) {
      configuredColumns = configuredColumns.filter((column) => allowedColumns.includes(column.def));
    } else {
      configuredColumns = configuredColumns.filter((column) => !Object.keys(CommonFrameEntityFieldsEnum).includes(column.def));
    }

    if (this.actionsEnabled) {
      const actions = this.getActions();
      if (actions.value.length) {
        configuredColumns.push(actions);
      }
    }

    if (!this.columnList) {
      this.columnList = configuredColumns;
    }
  }

  getActions() {
    this.actions.subscribe(async (action) => {
      if (action) {
        switch (action.type) {
          case "Modifica":
            this.goToView.emit({entityName: this.entityName, entityId: action.element.id});
            break;
          case "Elimina":
            this.openDeleteConfirmationDialog.emit(action.element);
            break;
        }
      }
    });

    const actions: any = {
      def: "actions",
      name: "AZIONI",
      value: [
        {icon: 'edit', type: "Modifica"},
        {icon: 'delete', type: "Elimina"}
      ],
    };

    return actions;
  }

  applyColumnsConfig(generatedColumns: any[]): ColumnModel[] {
    if (!this.entityName) {
      return generatedColumns;
    }
    const configColumns = this.entityConfig?.columns;
    if (!this.entityConfig || !configColumns?.length) {
      return generatedColumns;
    }
    
    // using config columns only
    if (this.entityConfig.ignoreGeneratedColumns) {
      return configColumns as ColumnModel[];
    }

    // updating generated columns with config columns
    for (const configColumn of configColumns as any[]) {
      const generatedColumnIndex = generatedColumns?.findIndex((_column) => _column.def === configColumn.def);
      if (generatedColumnIndex !== -1) {
        for (const columnConfigPropName of Object.keys(configColumn)) {
          generatedColumns[generatedColumnIndex][columnConfigPropName] = configColumn[columnConfigPropName];
        }
      } else {
        generatedColumns.push(configColumn as ColumnModel);
      }
    }
    return generatedColumns as ColumnModel[];
  }

  getGeneratedColumns(metadata: FrameEntityMetadataModel): ColumnModel[] {
    const columns = [];

    for (const metadataItem of metadata.columns) {
      const columnName = metadataItem.name;
      const columnType = metadataItem.type;
      const translatedColumnName = this.translationService.getTranslatedFieldName(this.entityName as string, columnName);
      const column: ColumnModel = {
        def: columnName,
        name: translatedColumnName,
        value: (element: any) => element[columnName],
        sort: true,
      };

      if (columnName === 'id') {
        column.type = FullTableColumnType.number;
        column.click = (element: any) => this.goToView.emit({entityName: this.entityName, entityId: element.id});
        column.tooltip = 'Visualizza dettaglio';
      } else {
        const mappedColumnType = frameEntityMapping(columnType);

        switch (mappedColumnType) {
          case FrameEntityType.number:
            column.type = FullTableColumnType.number;
            break;
          case FrameEntityType.date:
            column.value = (element: any) => this.datePipe.transform(
              element[columnName],
              this.dateFormat
            );
            column.type = FullTableColumnType.date;
            break;
          case FrameEntityType.string:
            column.value = (element: any) => element[columnName] || '';
            column.type = FullTableColumnType.string;
            break;
          case FrameEntityType.boolean:
            column.type = FullTableColumnType.boolean;
            break;
          default:
            console.log('unhandled', columnName, columnType);
            break;
        }
      }

      columns.push(column);
    }

    return columns;
  }

}
