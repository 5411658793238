import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
// import { environment } from '../../../environments/environment';
import { PwaInstallationDialogComponent } from '../../components/pwa-installation-dialog/pwa-installation-dialog.component';
import { PwaUpdateDialogComponent } from '../../components/pwa-update-dialog/pwa-update-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  deferredPrompt: any;

  constructor(private swUpdate: SwUpdate, private dialog: MatDialog) {
    // if (environment.ENVIRONMENT === 'local') {
    //   return;
    // }
    
    // intercetta quando è disponibile un nuovo aggiornamento
    this.swUpdate.checkForUpdate().then(checkForUpdate => {
      if (checkForUpdate) {
        this.swUpdate.activateUpdate().then(updateAvailable => {
          if (updateAvailable) {
            this.openPwaUpdateDialog();
          }
        });
      }
    });

    const pwaInstallationDeniedJSON = localStorage.getItem('pwainstallationdenied');
    let pwaInstallationDenied = false;
    if (pwaInstallationDeniedJSON) {
      pwaInstallationDenied = JSON.parse(pwaInstallationDeniedJSON);
    }
    if (!pwaInstallationDenied) {
      // intercetta la richiesta di installazione della pwa
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault();
        this.deferredPrompt = e;
        this.openPwaInstallationDialog();
      });
    }
  }

  openPwaInstallationDialog() {
    const dialogRef = this.dialog.open(PwaInstallationDialogComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.triggerAddToHomeScreen();
      } else {
        localStorage.setItem('pwainstallationdenied', JSON.stringify(true));
      }
    });
  }

  openPwaUpdateDialog() {
    const dialogRef = this.dialog.open(PwaUpdateDialogComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updatePwa();
      }
    });
  }

  updatePwa() {
    window.location.reload();
  }

  triggerAddToHomeScreen() {
    // richiede l'installazione della pwa
    this.deferredPrompt.prompt();
    // attende che l'utente accetti l'installazione
    this.deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        alert("L'installazione dell'App si è conclusa con successo!");
      } else {
        alert("Hai rifiutato l'installazione dell'App.");
      }
      this.deferredPrompt = null;
    });
  }
}
