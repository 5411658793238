import {SCondition} from "@nestjsx/crud-request";
import {OperatorsEnum} from "../../enums/operators.enum";
import {TypesEnum} from "../../enums/types.enum";
import {isBoolean, isNaN} from "lodash";
import {FilterOptionsEnum} from "../../enums/filter-options.enum";
import {ColumnModel} from "../../model/column.model";

export function getSearchOperation(op: string, value: any, type: TypesEnum): SCondition {
  switch (op) {
    case OperatorsEnum.NOT_NULL :
      return {$notnull: value};
    case OperatorsEnum.NULL :
      return {$isnull: value};
    case OperatorsEnum.NOT_EQUALS:
      return {$ne: value};
    case OperatorsEnum.GREATER_THAN:
      return {$gt: value};
    case OperatorsEnum.GREATER_THAN_EQUALS:
      return {$gte: value};
    case OperatorsEnum.LOWER_THAN:
      return {$lt: value};
    case OperatorsEnum.LOWER_THAN_EQUALS:
      return {$lte: value};
    case OperatorsEnum.EQUALS:
      switch (type) {
        case TypesEnum.NULLABLE:
          if (value === true) {
            return {$isnull: true};
          } else if (value === false) {
            return {$notnull : true};
          } else {
            return {};
          }
          case TypesEnum.STRING:
            return {$contL: value};
          default:
          return {$eq: value};
      }
    default :
      return {$eq: value};
  }
}

export function castTableCell(value: any, type?: string) {
  try {
    switch (type) {
      case 'date':
        if (!value) {
          return '';
        }
        const date = new Date(value);
        const isDateValid = date instanceof Date && !isNaN(date.getTime());
        return isDateValid ? date : value;
      case 'number':
        const number = Number(value);
        const isNumberValid = !isNaN(number);
        return isNumberValid ? number : value;
      case 'boolean':
        const boolean = Boolean(value);
        const isBooleanValid = isBoolean(boolean);
        return isBooleanValid ? boolean : value;
      case 'string':
        return value ? `${value}` : '';
      default:
        return value;
    }
  } catch (e) {
    return value;
  }
}

export function transformFilterOptionsInOperators(operation: FilterOptionsEnum){
  switch (operation){
    case FilterOptionsEnum.SPECIFY_VALUE:
      return OperatorsEnum.EQUALS;
    case FilterOptionsEnum.NULL:
      return OperatorsEnum.NULL;
    case FilterOptionsEnum.NOT_NULL:
      return OperatorsEnum.NOT_NULL;
  }
}

export function refactorParams(params: string[]) {
  const res: string[] = [];
  params.forEach(el => {
    res.push(el.slice(0, el.indexOf('__')));
  })
  return [...new Set(res)];
}

// shows true values of boolean filters Es. Visibilità IDR = false -> Visibilità IDR = Produzione
export function convertBooleanParamsValues(value: string, column: ColumnModel) {
  if (column.type == 'boolean') {
    switch(value) {
      case 'true': return true;
      case 'false': return false;
    }
  }
  return value;
}


