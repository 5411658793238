import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { ListResponseModel } from '../../../shared/models/list-response.model';
import { HttpCallerService } from '../../../shared/services/http-caller/http-caller.service';
import { FrameEntityMetadataModel } from '../../models/frame-entity-metadata.model';
import { FrameEntityModel } from '../../models/frame-entity.model';

@Injectable({
  providedIn: 'root'
})
export class FrameEntityHttpService<T extends FrameEntityModel> extends HttpCallerService {

  constructor(
    protected override readonly http: HttpClient,
    @Inject('BASE_PATH') protected backendBasePath: string,
  ) {
    super(http, backendBasePath);
  }

  async getMetadata(entityName: string): Promise<FrameEntityMetadataModel> {
    return await this.get<FrameEntityMetadataModel>(`/${entityName}/entity/metadata`);
  }

  async getOne(entityName: string, entityId: number, query?: string) {
    return await this.get<T>(`/${entityName}/${entityId}?${query ?? ''}`);
  }

  async getMany(entityName: string, query?: string) {
    return await this.get<ListResponseModel<T>>(`/${entityName}?${query ?? ''}`);
  }

  async createOne(entityName: string, entity: T) {
    return await this.post<T>(`/${entityName}`, entity);
  }

  async updateOne(entityName: string, entity: T) {
    return await this.patch(`/${entityName}/${entity.id}`, entity);
  }

  async deleteOne(entityName: string, entityId: number) {
    return await this.delete(`/${entityName}/${entityId}`);
  }

}
