import { Component, Input, ViewChild } from '@angular/core';
import { RouterModule, ActivatedRoute, Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { FrameEntityTableComponent } from '../frame-entity-table/frame-entity-table.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog } from '@angular/material/dialog';
import { FrameEntityHttpService } from '../../services/frame-entity-http/frame-entity-http.service';
import { ConfirmationDialogComponent } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { SnackBarService } from '../../../shared/services/snackbar/snackbar.service';
import { TranslationService } from '../../../shared/services/translation/translation.service';

@Component({
  selector: 'lib-frame-entity-list',
  standalone: true,
  imports: [
    RouterModule, 
    NgIf,
    FrameEntityTableComponent,
    MatButtonModule,
    MatProgressSpinnerModule,
    FrameEntityTableComponent,
  ],
  templateUrl: './frame-entity-list.component.html',
  styleUrl: './frame-entity-list.component.scss'
})
export class FrameEntityListComponent {

  entityListEnabled: boolean = true;
  @Input() entityName?: string;
  translatedEntityName?: string;
  @ViewChild(FrameEntityTableComponent, { static: false }) childC!: FrameEntityTableComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private frameEntityHttpService: FrameEntityHttpService<any>,
    private snackBarService: SnackBarService,
    private translationService: TranslationService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe((params) => {
      const entityName = params?.['entityName'];
      if (!this.entityName && entityName) {
        this.entityName = entityName;
      }
      if (this.entityName) {
        this.translatedEntityName = this.translationService.getTranslatedEntityName(this.entityName as string);
        this.refreshEntityList();
      } 
     });
  }

  async goToView(event?: {entityName?: string, entityId?: number}) {
    return this.router.navigateByUrl(`/frame/entity/${event?.entityName || this.entityName}/${event?.entityId || 'create'}`);
  }

  openDeleteConfirmationDialog(entity: any) {
    const entityName = this.entityName;
    if (!entityName) {
      return;
    }
    const entityId = entity.id as number;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: `Eliminazione ${entityName}`, 
        description: `Sei sicuro di voler eliminare l'entità ${this.translatedEntityName} con ID ${entityId}?`
      }
    });
    dialogRef.afterClosed().subscribe(
      async (result) => {
        if(result) {
          await this.frameEntityHttpService.deleteOne(entityName, entityId);
          this.refreshEntityList();
          this.snackBarService.openInfoSnackBar(`Entità ${this.translatedEntityName} eliminata con successo`);
        }
      }
    )
  }

  refreshEntityList() {
    this.entityListEnabled = false;
    setTimeout(() => {
      this.entityListEnabled = true;
    });
  }
}
