import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {ColumnModel} from '../model/column.model';
import moment from 'moment';
import {ChipListItem} from '../model/chip-list-item.model';
import {OperatorsEnum} from '../enums/operators.enum';
import {FilterOptionsEnum} from '../enums/filter-options.enum';
import {TypesEnum} from '../enums/types.enum';
import {SpecialColumnsEnum} from '../enums/special-columns.enum';
import {transformFilterOptionsInOperators} from '../shared/utils/utils';
import {QueryParamsService} from '../services/query-params.service';
import {DefaultFilterModel} from '../model/default-filter.model';
import {FormColumn, GetColumnFilterOptionsPipe} from '../pipes/get-column-filter-options.pipe';
import {GenericWithId} from '../interfaces/generic-with-id.interface';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatAutocompleteModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    GetColumnFilterOptionsPipe,
    AsyncPipe,
  ],
  providers: [
    GetColumnFilterOptionsPipe,
    AsyncPipe,
  ],
  selector: 'lib-full-table-filter',
  templateUrl: `./full-table-filter.component.html`,
  styleUrls: ['./full-table-filter.component.scss']
})
export class FullTableFilterComponent<T extends GenericWithId> implements OnInit, AfterViewInit {

  @Input() actions = new EventEmitter<void | { type: string, element: T }>();
  @Input() chipList!: ChipListItem[];
  @Input() columnList!: ColumnModel[];
  @Input() defaultFilter?: DefaultFilterModel[];
  filterForm: FormGroup;
  filterOptionsRadioGroup = new FormControl();
  filterOptions = Object.values(FilterOptionsEnum);

  filteredColumnsForm$: Observable<ColumnModel[]> = new Observable<ColumnModel[]>();
  protected readonly FilterOptionsEnum = FilterOptionsEnum;
  protected readonly TypesEnum = TypesEnum;

  constructor(
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private queryParamsService: QueryParamsService,
    @Inject('BASE_PATH') BASE_PATH: string,
  ) {
    this.filterForm = fb.group({
      column: [''],
      operation: ['='],
      value: [''],
      start: [''],
      end: [''],
      isExactValue: false,
    });
  }

  ngOnInit(): void {
    this.initializeFilters();
  }

  // Initialize filter form related logic
  initializeFilters(): void {
    this.filterOptionsRadioGroup.setValue(FilterOptionsEnum.SPECIFY_VALUE);

    const columControl = this.filterForm.get('column');
    if (columControl) {
      this.filteredColumnsForm$ = columControl.valueChanges.pipe(
        startWith(''),
        map(value =>
          this.columnList.filter(col =>
            col.name.toLowerCase().includes(value ? value.toLowerCase() : '') && col.type && col.def != SpecialColumnsEnum.selection
          )
        )
      );
    }
  }

  async ngAfterViewInit() {
    this.setupColumnControl();
  }

  setupColumnControl() {
    const columnControl = this.filterForm.get('column');
    if (columnControl) {
      columnControl.valueChanges.subscribe(() => {
        // for boolean init false
        const valueControl = this.filterForm.get('value');
        if (valueControl) {
          if (this.getFilterColumnType() === 'boolean' && !valueControl.value) {
            valueControl.setValue(false);
          }
        }
      });
    }
  }

  closeFilterMenu() {
    Object.keys(this.filterForm.controls).forEach((key) => {
      this.filterForm?.get(key)?.setValue(null);
    });
  }

  selectFilter(column: ColumnModel) {
    this.filterForm?.get('column')?.setValue(column.name);
  }

  applyFilter(isConfirmed?: boolean): void {
    const value = this.filterForm?.value?.value;
    const start = this.filterForm?.value?.start;
    const end = this.filterForm?.value?.end;
    const isNullOrNotNull = this.filterOptionsRadioGroup.value && this.filterOptionsRadioGroup.value != FilterOptionsEnum.SPECIFY_VALUE;

    if (isConfirmed) {
      if (isNullOrNotNull) {
        const isNotNull: boolean = this.filterOptionsRadioGroup.value === FilterOptionsEnum.NOT_NULL;
        const oldFilter = this.chipList.find((chip) => chip.operation === (isNotNull ? OperatorsEnum.NULL : OperatorsEnum.NOT_NULL) &&
          chip.column === this.filterForm.value.column);
        if (oldFilter) {
          const oldFilterIndex: number = this.chipList.indexOf(oldFilter);
          this.chipList.splice(oldFilterIndex, 1);
        }
        this.filterForm.value.value = true;
      }
    }
    this.filterForm.value.operation = transformFilterOptionsInOperators(this.filterOptionsRadioGroup.value);

    if (start && moment.isMoment(start)) {
      this.filterForm.controls.start.setValue(moment(start).toISOString());
    }
    if (end && moment.isMoment(end)) {
      this.filterForm.controls.end.setValue(moment(end).toISOString());
    }

    if (((value || value === false) || start || end) || isNullOrNotNull) {
      this.chipList.push(this.filterForm.value);
    }
    this.filterOptionsRadioGroup.setValue(FilterOptionsEnum.SPECIFY_VALUE);
    this.filterForm.reset();
    this.actions.emit();
  }

  getFilterColumnType(): string | null {
    const columnControl = this.filterForm.get('column');
    if (columnControl) {
      const col = columnControl.value;
      if (col) {
        const cl = this.columnList.find(c => c.name === col);
        // if is is specified
        if (cl && cl.type) {
          return cl.type;
        }
        // string is default
        return 'string';
      }
    }
    return null;
  }

  hasBoolishColumnType() {
    const columnType = this.getFilterColumnType();
    return columnType === TypesEnum.BOOLEAN || columnType === TypesEnum.NULLABLE;
  }

  getFilterDefault(): { title: string, value: string }[] {
    const columnControl = this.filterForm.get('column');
    if (columnControl) {
      const col = columnControl.value;
      if (col) {
        const cl = this.columnList.find(c => c.name === col);
        if (cl && cl.filterDefault) {
          return cl.filterDefault;
        }
      }
    }
    return [];
  }

  getFormColumn(): FormColumn{
   return  this.filterForm.get('column');
  }

}
