import { registerLocaleData } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AuthModule } from "./auth/auth.module";
import { FrameEntityModule } from "./frame-entity/frame-entity.module";
import { DynamicFormConfig } from "./frame-entity/models/dynamic-form-config.model";
import { FullTableConfig } from "./frame-entity/models/full-table-config.model";
import { HttpConfig } from "./frame-entity/models/http-config.model";
import { TranslationConfig } from "./frame-entity/models/translation-config.model";
import { FrameEntityHttpService } from "./frame-entity/services/frame-entity-http/frame-entity-http.service";
import { metaReducers, reducers } from "./shared/reducers";
import { UserModule } from "./user/user.module";
import localeIt from '@angular/common/locales/it';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

registerLocaleData(localeIt, 'it-IT');

@NgModule({
  imports: [
    AuthModule,
    UserModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([]),
    FrameEntityModule,
  ],
  providers: [
    provideAnimationsAsync()
  ],
  declarations: [],
  bootstrap: [],
  exports: [],
})
export class FrameModule {
    static forRoot(
      config: {
        dateFormat?: string,
        dateTimeFormat?: string,
        httpConfig: HttpConfig, 
        fullTableConfig?: FullTableConfig,
        dynamicFormConfig?: DynamicFormConfig,
        translationConfig?: TranslationConfig,
      }
    ): ModuleWithProviders<FrameModule> {
      return {
        ngModule: FrameModule,
        providers: [
          FrameEntityHttpService, 
          {provide: 'dateFormat', useValue: config.dateFormat},
          {provide: 'dateTimeFormat', useValue: config.dateTimeFormat},
          {provide: 'BASE_PATH', useValue: config.httpConfig.basePath},
          {provide: 'fullTableConfig', useValue: config.fullTableConfig},
          {provide: 'dynamicFormConfig', useValue: config.dynamicFormConfig},
          {provide: 'translationConfig', useValue: config.translationConfig},
        ]
      };
    }
  }

