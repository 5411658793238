import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ColumnModel, FullTableComponent } from '@overflo-srl/full-table';
import { firstValueFrom, from } from 'rxjs';
import { FramePermissions } from '../../../../shared/enums/frame.permissions';
import { ConfirmationDialogComponent } from "../../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { HasPermissionPipe } from '../../../../shared/pipes/has-permission/has-permission.pipe';
import { SnackBarService } from '../../../../shared/services/snackbar/snackbar.service';
import { RoleFunctionModel } from "../../../model/role-function.model";
import { RoleFunctionService } from "../../../service/role-function.service";
import { FunctionEditDialogComponent } from "../../function-edit-dialog/function-edit-dialog.component";

@Component({
  standalone: true,
  imports: [
    FullTableComponent,
  ],
  selector: 'app-function-list',
  templateUrl: './function-list.component.html',
  styleUrls: ['./function-list.component.scss'],
})
export class FunctionListComponent implements OnInit {

  functionListEnabled: boolean = true;
  actions = new EventEmitter<void | {
    type: string;
    element: RoleFunctionModel;
  }>();

  columnList?: ColumnModel[];

  constructor(
    private dialog: MatDialog,
    private roleFunctionService: RoleFunctionService,
    private snackBarService: SnackBarService,
    private hasPermissionPipe: HasPermissionPipe,
  ) { }

  async ngOnInit() {
    await this.prepareColumnList();
    this.actions.subscribe(async (action) => {
      if (action) {
        switch (action.type) {
          case "Modifica":
            await this.openFunctionEditDialog(action.element);
            break;
          case "Elimina":
            await this.delete(action.element);
            break;
        }
      }
    });
  }

  async prepareColumnList() {
    const columns = [
      {
        def: "name",
        name: "NOME",
        value: (element: RoleFunctionModel) => element.name,
        sort: true,
        type: "string",
      },
      {
        def: "group",
        name: "GRUPPO",
        value: (element: RoleFunctionModel) => element.group,
        sort: true,
        type: "string",
      },
      {
        def: "description",
        name: "DESCRIZIONE",
        value: (element: RoleFunctionModel) => element.description,
        sort: true,
        type: "string",
      },
    ];

    const actions: any = {
      def: "actions",
      name: "AZIONI",
      value: [],
    };

    if (await firstValueFrom(this.hasPermissionPipe.transform([FramePermissions.roleFunctionEdit]))) {
      actions.value.push({
        icon: 'edit',
        type: "Modifica",
      });
    }

    if (await firstValueFrom(this.hasPermissionPipe.transform([FramePermissions.roleFunctionDelete]))) {
      actions.value.push({
        icon: 'delete',
        type: "Elimina",
      });
    }

    if (actions.value.length) {
      columns.push(actions);
    }

    this.columnList = columns;
  }

  async delete(roleFunction: RoleFunctionModel) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: `Cancellazione funzione`,
        description: `Sei sicuro di voler eliminare la funzione ${roleFunction.name} ?`
      }
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          from(this.roleFunctionService.deleteOne(roleFunction.id))
            .subscribe({
              next: value => {
                this.snackBarService.openInfoSnackBar(`Funzione eliminata con successo`);
                return this.actions.emit();
              },
              error: err => {
                this.snackBarService.openErrorSnackBar(`${err.error.message}`);
                return this.actions.emit()
              },
            })
        }
      }
    )
  }

  openFunctionEditDialog(roleFunction?: RoleFunctionModel) {
    const dialogRef = this.dialog.open(FunctionEditDialogComponent, { data: { roleFunction: roleFunction } });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.refreshFunctionList();
          if (result !== true) {
            this.actions.emit();
          }
        }
      }
    )
  }

  create() {
    this.openFunctionEditDialog();
  }

  refreshFunctionList() {
    this.functionListEnabled = false;
    setTimeout(() => {
      this.functionListEnabled = true;
    });
  }

}
