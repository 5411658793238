@if (isLoading && (error$ | async) === '') {
  <mat-progress-bar color="warn" mode="query"></mat-progress-bar>
}

<div class="login-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="login-header" fxLayout="column" fxLayoutAlign="space-evenly center">
    <img src="/img/logo.png" />
  </div>
  <h3 class="welcome">{{!forgottenPasswordEnabled ? 'Benvenuto' : 'Ripristino password'}}</h3>
  <div class="white-box-container">

    @if (!forgottenPasswordEnabled) {
      <form [formGroup]="loginForm" class="login-form">
        <div class="login-form-field">
          <label>Username</label>
          <mat-form-field appearance="outline">
            <input autocomplete="off" formControlName="username" matInput type="text" data-cy="loginUsername"/>
          </mat-form-field>
        </div>
        <div class="login-form-field">
          <label>Password</label>
          <mat-form-field appearance="outline">
            <input autocomplete="off" formControlName="password" matInput type="password" data-cy="loginPassword"/>
          </mat-form-field>
        </div>
        @if (error$ | async) {
          <mat-error class="login-error" data-cy="loginError">{{ error$ | async }}</mat-error>
        }
        <div class="login-actions">
          <div class="forgotten-password-link" (click)="toggleForgottenPasswordEnabled()">Password dimenticata?</div>
          <button mat-raised-button class="login-button" color="accent" (click)="login()" type="submit" [disabled]="isLoading && (error$ | async) === ''" data-cy="loginButton">Accedi</button>
        </div>
      </form>
    }

    @if (forgottenPasswordEnabled) {
      <div class="paragraph">Invieremo un link per il ripristino della password al tuo indirizzo email.</div>
      <form [formGroup]="forgottenPasswordForm" class="forgotten-password-form">
        <div class="forgotten-password-form-field">
          <label>Email</label>
          <mat-form-field appearance="outline">
            <input autocomplete="off" formControlName="email" matInput type="text" data-cy="forgottenPasswordEmail"/>
          </mat-form-field>
        </div>
        @if (forgottenPasswordEmailInvalid) {
          <mat-error class="forgotten-password-error" data-cy="forgottenPasswordError">Formato email non valido</mat-error>
        }
        <div class="forgotten-password-actions">
          <div class="forgotten-password-link" (click)="toggleForgottenPasswordEnabled()">Accedi</div>
          <button mat-raised-button class="login-button" color="accent" type="submit" (click)="requestPasswordReset()" [disabled]="isLoading && (error$ | async) === ''" data-cy="forgottenPasswordButton">Invia</button>
        </div>
      </form>
    }
  </div>
</div>
